import React, { useEffect, useState } from 'react'
import "./Contact.css";
import { Link } from 'react-router-dom';
import { IoLocationSharp } from "react-icons/io5";
import { MdAddIcCall } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import contact from "../../../assets/contact.jpg";
import emailjs from "@emailjs/browser";




export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check if form is valid
    const form = e.target;
    const name = form.elements.name.value.trim();
    const email = form.elements.email.value.trim();
    const subject = form.elements.subject.value.trim();
    const message = form.elements.message.value.trim();
  
    if (!name || !email || !subject || !message) {
      // If any of the fields are empty, set form validity to false
      setIsFormValid(false);
      return; // Don't proceed with form submission
    }
  
    // Here you can add logic to send the email
    // For demonstration, I'll just set isSubmitted to true
    let userFormData = { name, email, subject, message };
    const service_id = "service_c3hfv37";
    const temple_id = "template_60v19lu";
    const public_key = "msaGc10pGO2gynHNx";
    console.log(name, email, subject, message);
    const templateParams = {
      form_name: "Ak",
      reply_to: "adarshkadamak47@gmail.com",
      from_email: "adarshkadamak47@gmail.com",
      to_name: "Adarsh",
      message: `Name : ${name} Email: ${email} Subject: ${subject} Message : ${message}`,
    };
    emailjs
      .send(service_id, temple_id, templateParams, public_key)
      .then((response) => {
        setIsSubmitted(true);
        // Clear the form fields
        form.reset();
      })
      .catch((err) => {
        setIsFormValid(true); // Reset form validity
        console.log(userFormData);
      });
  
    console.log("i am contact");
  };
  return (
    <>
      <section className="contact_sec2 aos-init aos-animate" data-aos="fade-in">
        <div className="container">
          <div className="text_container">
            <h4 className="breadcrumb">Connect With Us</h4>
            <h2 className="heading">Here's Where You Can Find Us</h2>
            <br />
          </div>
          <div className="card-container">
            <div className="card2">
              <div className="box_container">
                <IoLocationSharp className="icon_img" />
                <h4 className="heading">Registered Address</h4>
                <p className="desc">Shop no.596,Krishnaraj Gally, M.J Market,
                  Mumbai Maharashtra.</p>
              </div>
            </div>
            <div className="card2">
              <div className="box_container">
                <Link className="number" to="tel:+91 97692 47050">
                  <MdAddIcCall className="icon_img" />
                  <h4 className="heading">Call Us On</h4>
                  <p className="desc">+91 97692 47050<br />
                    <strong>Days of operation</strong><br />- Monday - Saturday <br />
                    <strong>Hours of operation</strong><br />- 9:30 AM - 6:30 PM
                  </p>
                </Link>
              </div>
            </div>
            <div className="card2">
              <div className="box_container">
                <Link className="number" to="mailto:sujitenterprises.2014@gmail.com">
                  <IoIosMail className="icon_img" />
                  <h4 className="heading">Write To Us On</h4>
                  <p className="desc">
                    sujitenterprises.2014@gmail.com
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container'>
        <div className='about-content'>
          <h2 className='aboutpage'>For Enquiry</h2>
          <br />
          <div className='flex2'>
            <div className="image-wrapper">
              <img src={contact} alt="img" className="about-image" />
            </div>
            <div className="formdiv">
            <h3>Enquiry</h3>
            <form
              onSubmit={handleSubmit}
              className="form"
            >
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Name:
                </label>
                <br/>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className='filter'
                 
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                >
                  Email Address:
                </label>
                <br/>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className='filter'
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="subject"
                
                >
                  Subject:
                </label>
                <br/>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className='filter'
              
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="message"
                >
                  Your Message:
                </label>
                <br/>
                <textarea
                  id="message"
                  name="message"
                  className='filter'
                ></textarea>
              </div>
              <div className="flex items-center justify-center">
              <button className='button' type="submit">Send Message</button>
              </div>
              {!isFormValid && (
                <div className="bg-red-500 text-white px-4 py-2 mt-4 rounded">
                  Please fill in all fields.
                </div>
              )}
              {isSubmitted && (
                <div className="bg-green-500 text-white px-4 py-2 mt-4 rounded">
                  Email has been sent to the owner.
                </div>
              )}
            </form>
            </div>
          </div>
        </div>
      </section>
      <br/>
    </>
  )
}
