export const sliderData = [
    {
      image: "https://bondwiththebest.com/wp-content/uploads/2023/11/lookbook-img.png?id=956",
      heading: "Sujit Enterprises",
      desc: "Explore our Collection.",
    },
    {
      image: "https://images.unsplash.com/photo-1633655442136-bbc120229009?q=80&w=1476&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      heading: "Sujit Enterprises",
      desc: "Explore our Collection.",
    },
    {
      image: "https://images.unsplash.com/photo-1481608726045-7407244fb7b6?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      heading: "Sujit Enterprises",
      desc: "Explore our Collection.",
    },
    {
      image: "https://images.unsplash.com/photo-1489370603040-dc6c28a1d37a?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      heading: "Sujit Enterprises",
      desc: "Explore our Collection.",
    },
    {
        image: "https://images.unsplash.com/photo-1491336477066-31156b5e4f35?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        heading: "Sujit Enterprises",
        desc: "Explore our Collection.",
      },
    //   {
    //     image: "",
    //     heading: "Women Fashion",
    // desc: "Up to 30% off on all onsale proucts.",
    //   },
  ];