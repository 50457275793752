import axios from 'axios';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import './ServicesAdmin.css';

function AddListing() {
    // const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [image, setImage] = useState('');
    const [status, setStatus] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleChangeDesc = (e) => {
        setDesc(e.target.value);
    };

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            setImage(e.target.files[0]);
        } else {
            setError('Please select an image file.');
        }
    };

    const handleClick = () => {
        if (!title || !desc || !image) {
            setError('Please fill in all fields.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', desc);
        formData.append('image', image);

        axios
            .post("https://sujit-backend.onrender.com/api/services", formData, {
                headers: { Authorization: localStorage.getItem('token') },
            })
            .then((res) => {
                console.log(res.data);
                setStatus('Added successfully!');
                setError('');
            })
            .catch((err) => {
                console.log(err, 'err');
                setStatus('');
                setError('Error: Failed to add service.');
            });
    };

    return (
        <div className="admin-card">
            <input value={title} onChange={handleChange} placeholder="title" className="input-admin" /> <br />
            <input value={desc} onChange={handleChangeDesc} placeholder="description" className="input-admin" />{' '}
            <br />
            <input onChange={handleImageChange} type="file"  />
            <br/>
            <br/>
            <button onClick={handleClick} className="addbutton">
                Upload
            </button>
            {error && <p>{error}</p>}
            {status && <p>{status}</p>}
        </div>
    );
}

export default AddListing;
