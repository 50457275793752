import React from "react";
import styles from "./Footer.module.scss";

// const date = new Date();
// const year = date.getFullYear();

const Copy = () => {
  return <div className={styles.copy}>&copy; www.sujitenterprises.com 2024 All Rights Reserved.</div>;
};

export default Copy;