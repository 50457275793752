import React, { useEffect } from 'react'
import img from "../../../assets/Since.png";
import img2 from "../../../assets/About Us.png";
import "./About.scss";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className='container'>
        <div className="about-content">
          <h2 className="aboutpage">About Us</h2>
          <br />
          <h3>Sujit Enterprises</h3>
          <br />
          <div className='flex'>
            <p className="section-2">Our incorporation, <strong>“Sujit Enterprises”</strong> is working in this industry <strong>2014</strong>. These years of efforts come in an outstanding way that we are gratifying the huge clients’ base. Our association is a <strong>Individual - Proprietor</strong> based venture. We have settled our main head office at <strong>Mumbai, Maharashtra</strong>. We are matching up with the never ending necessities of the customers, our company is a leading <stromg>Wholesale Supplier</stromg> of <strong>Polyester Fabric, Cotton Shirting Fabric, Poly Viscose Fabric, Velvet Fabric </strong> and further more, All the products under the keen supervision of our ardent professional.</p>
            <div className="image-wrapper">
              <img src={img} alt="img" className="about-image" />
            </div>
          </div>
        </div>
      </section>
      <section className='container'>
        <div className='about-content'>
          <h2 className='aboutpage'>Why Us?</h2>
          <br />
          <div className='flex'>
          <div className="image-wrapper">
              <img src={img2} alt="img" className="about-image" />
            </div>
            <p className="section-desc">Owing to our professional approach, skilled team of experts and transparent dealings, we have been able to attain huge client base.
              <br />
              <br />
              <h4>We have got the edge over other market players due to the following reasons mentioned below:
                <br />
                <br/>
                <div className="list">
                <ul>
                  <li>Optimum quality products</li>
                  <li>Timely delivery</li>
                  <li>Cost-effective price</li>
                  <li>Competent and efficient team</li>
                  <li>Efficient logistic facility</li>
                  <li>Vast industry experience</li>
                </ul>
                
                </div>
              </h4>
            </p>
            
          </div>
        </div>
      </section>
    </>
  )
}
