import React, { useEffect, useState } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import PropTypes from 'prop-types';
import { sliderData } from "./slider-data";
import "./Slider.scss";
import { Link } from 'react-router-dom';

const Slider = ({ intervalTime = 8000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = sliderData.length;

  const autoScroll = true;
  let slideInterval;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    const auto = () => {
      slideInterval = setInterval(nextSlide, intervalTime);
    };

    if (autoScroll) {
      auto();
    }

    return () => clearInterval(slideInterval);
  }, [currentSlide, intervalTime, autoScroll]);

  return (
    <>
    <div className="container">
    <div className="slider">
      <GrPrevious className="arrow prev" onClick={prevSlide} alt="Previous Slide" />
      <GrNext className="arrow next" onClick={nextSlide} alt="Next Slide" />

      {sliderData.map((slide, index) => {
        const { image, heading, desc } = slide;
        return (
          <div
            key={index}
            className={index === currentSlide ? "slide current" : "slide"}
          >
            {index === currentSlide && (
              <>
                <img src={image} alt="slide" />
                <div className="content">
                <h3>{heading}</h3>
                  <p>{desc}</p>
                  <hr />
                  <Link to="/collection" className="sliderbutton">
                    Our Collection
                  </Link>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
    </div>
    </>
  );
};

Slider.propTypes = {
  intervalTime: PropTypes.number,
};

export default Slider;
