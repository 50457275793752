import React, { useState } from 'react';
import './Header.css';
import { Link, NavLink } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import logo from "../../assets/sujit logo.png";


const activeLink = (isActive) => (isActive ? "Active" : "");

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPage, setScrollPage] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  const fixNavbar = () => {
    if (window.scrollY > 50) {
      setScrollPage(true);
    } else {
      setScrollPage(false);
    }
  };
  window.addEventListener("scroll", fixNavbar);

  return (
    <>
      <header className={scrollPage ? "fixed" : null}>
        <div className='header'>
          <Link className="logo_link" to="/" onClick={hideMenu}>
            <div className="logo_wrapper">
              <img src={logo} alt="Sujit logo" className="raymond_logo" />
            </div>
          </Link>
          <nav className={showMenu ? "show-nav" : "hide-nav"}>
            <div className={showMenu ? "nav-wrapper show-nav-wrapper" : "nav-wrapper"} onClick={hideMenu}></div>
            <ul className="menu" onClick={hideMenu}>
              <li className="logo-mobile">
                <FaTimes size={22} color="#fff" onClick={hideMenu} />
              </li>
              <li className="nav_item">
                <NavLink to="/ourbusiness" activeClassName={activeLink}>Our Businesses</NavLink>
              </li>
              <li className="nav_item">
                <NavLink to="/about" activeClassName={activeLink}>About us</NavLink>
              </li>
              <li className="nav_item">
                <NavLink to="/collection" activeClassName={activeLink}>Our collections</NavLink>
              </li>
              <li className="nav_item">
                <NavLink to="/contact" activeClassName={activeLink}>Contact us</NavLink>
              </li>
              <li className="nav_item" >
                <NavLink to="/store" activeClassName={activeLink}>Store locator</NavLink>
              </li>
            </ul>
          </nav>
          <div className="menu-icon">
            <HiOutlineMenuAlt2 size={28} onClick={toggleMenu} />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
