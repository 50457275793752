import React, { useEffect, useState } from "react";
import './Collection.css';
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../reducers/serviceReducer";

function Collection(props) {
    const dispatch = useDispatch();
    const state = useSelector(state => state.serviceReducer);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        dispatch(getServices())
        .then(() => setLoading(false))
        .catch(error => {
            console.error('Error fetching services:', error);
            setLoading(false);
        });
    }, [dispatch]);

    return (
        <>
            <section className='container'>
                <div className="about-content">
                    <h2 className="aboutpage">Our Collections</h2>
                </div>
            </section>
            <div className="search-container">
                {!props.hideSearchBar && (
                    <input
                        placeholder="Search"
                        className="filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                )}
            </div>

            {loading && (
                <div className="loading-container">
                    <div className="loading"></div>
                </div>
            )}

            <div className="flex container">
                {state &&
                    state.servicesData &&
                    state.servicesData.length > 0 ? (
                        state.servicesData
                            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by creation date in descending order
                            .filter(item => {
                                return item.title.toLowerCase().includes(filter.toLowerCase()) || item.description.toLowerCase().includes(filter.toLowerCase());
                            })
                            .map((serviceItem, serviceIndex) => (
                                <div style={{ padding: '10px 0px' }} key={serviceIndex + 1} className="card">
                                    <img width="100%" height="300px" src={`https://sujit-backend.onrender.com/${serviceItem?.imageUrl}`} alt={serviceItem.title} />
                                    <div className="title"> {serviceItem.title}</div>
                                    <div className="desc"> {serviceItem.description}</div>
                                </div>
                            ))
                    ) : (
                        !loading && 'No Data Found'
                    )}
            </div>
        </>
    );
}

export default Collection;
