import React, { useEffect }  from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/pages/home/Home";
import About from "./components/pages/about/About";
import Header from "./components/header/Header";
import Ourbusiness from "./components/pages/our business/Ourbusiness";
import AddListing from "./components/Gallery/AddListing";
import Collection from "./components/Gallery/Collection";
import Footer from "./components/footer/Footer";
import Store from "./components/pages/store locator/Store";

import { Provider } from "react-redux"
import store from './store'
import Contact from "./components/pages/contact/Contact";



const App = () => {
 
  return (
<>
<Provider store={store} >
<BrowserRouter>
<Header/>
<Routes>
  <Route path="/" element={<Home/>}/>
  <Route path="/about" element={<About/>}/>
  <Route path="/ourbusiness" element={<Ourbusiness/>}/>
  <Route path="/sujit" element={<AddListing/>}/>
  <Route path="/collection" element={<Collection/>}/>
  <Route path="/store" element={<Store/>}/>
  <Route path="/contact" element={<Contact/>}/>


</Routes>
<Footer/>
</BrowserRouter>
</Provider>
</>
  );
};

export default App;
