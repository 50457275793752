import React, { useEffect, useState } from 'react';
import './map.css';
import { CiShop } from "react-icons/ci";

function Store() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <>
      <section>
        <div className='container'>
          <div className="map-container">
            {!mapLoaded && <div className="loading">Loading Map...</div>}
            <iframe
              title="Location Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.597329189608!2d72.82803687520226!3d18.949209482229396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cf3c17aa7795%3A0x5163f5f66a84e1a1!2sSUJIT%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1709972886876!5m2!1sen!2sin"
              className={`map-frame ${mapLoaded ? 'show' : 'hide'}`}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              onLoad={handleMapLoad}
            ></iframe>
          </div>
        </div>
      </section>
      <section className='container'>
        <div className='about-content'>
          <h2 className='aboutpage'>Our Reach</h2>
          <br />
          <div className="card-container">
            <div className="card2">
              <div className="box_container">
              <CiShop className="icon" />
                <h4 className="heading">Mumbai, Maharashtra</h4>
                <p className="desc">Shop no.596,Krishnaraj Gally, M.J Market,
                  Mumbai Maharashtra.</p>
              </div>
            </div>
            <div className="card2">
              <div className="box_container">
              <CiShop className="icon" />
                <h4 className="heading">Bhiwandi, Maharashtra</h4>
                <p className="desc">COMING SOON.</p>
              </div>
            </div>
            </div>
        {/* <div class='two-div'>
            <div class='add1'>
              <CiShop className='icon' />
              <br/>
              <br/>
              <h4>Mumbai, Maharashtra</h4>
              <p>Shop no.596,Krishnaraj Gally, M.J Market,<br/>Mumbai Maharashtra</p>
            </div>
            <div class='add1'>
              <CiShop className='icon' />
              <br/>
              <br/>
              <h4>Bhiwandi, Maharashtra</h4>
              <p>COMING SOON</p>
            </div>
          </div> */}
        </div>
      </section>
      <br/>
    </>
  );
}

export default Store;
