import React, { useEffect } from 'react'
import "./ourbusiness.css";
import { FaBriefcase } from "react-icons/fa";
import ethnic2 from "../../../assets/ethinic2.jpg";
import suiting from "../../../assets/suiting2.jpg";
import { Link } from 'react-router-dom';

export default function Ourbusiness() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="container">
        <div className="about-content3">
          <h2 className="section-title3">Sujit Enterprises - Mumbai, Maharashtra</h2>
          <br />
          <h3 className='head'>Business Dealing In</h3>
          <br />
          <div class='businesslist'>
            <ul>
              <li><FaBriefcase className="business-icon" />Best Manufacturer Of Poly T R Suiting Fabric</li>
              <li><FaBriefcase className="business-icon" />Best Manufacturer Of Poly T R Suiting Fabric</li>
              <li><FaBriefcase className="business-icon" />Best Manufacturer Of Poly T R Suiting Fabric</li>
            </ul>
            <ul>
              <li><FaBriefcase className="business-icon" />Best Manufacturer Of Poly T R Suiting Fabric</li>
              <li><FaBriefcase className="business-icon" />Best Manufacturer Of Poly T R Suiting Fabric</li>
            </ul>
          </div>
        </div>
      </section>
      <br />
      <section className='container'>
        <div >
          <p className='para'>Welcome to Sujit Enterprises, your premier destination for exquisite suiting, shirting, and ethnic wear in Mumbai. With a rich legacy of 10 years in the industry, we have established ourselves as connoisseurs of fine textiles, blending tradition with contemporary style to offer you unparalleled elegance.</p>
        </div>
        <div className="about-content3">
          <h2 className="section-title6">Suiting</h2>
          <br />
          <div className='flex'>
            <p className="section-desc">Our suiting collection exemplifies sophistication and craftsmanship, curated to perfection for those who appreciate the finer details. From luxurious fabrics to impeccable tailoring, each garment is crafted with precision to ensure a perfect fit and unmatched comfort, making a lasting impression in any setting.</p>
            <div className="image-wrapper">
              <img className="suiting" src={suiting} alt="img" />
            </div>
          </div>
        </div>
        <div className="about-content3">
          <h2 className="section-title6">Shirting</h2>
          <br />
          <div className='flex'>
            <div className="image-wrapper">
              <img className="suiting" src="https://siyaram-images.s3.ap-south-1.amazonaws.com/images/brands/siyaram-suitings-and-shirtings/box2.jpg" alt="img" />
            </div>
            <br />
            <p className="section-desc">In our shirting range, we bring together a fusion of classic designs and modern sensibilities, offering a versatile selection to elevate your everyday wardrobe. Whether you seek understated elegance or bold statements, our collection caters to every taste and occasion, ensuring you always dress to impress.</p>
          </div>
        </div>
        <div className="about-content3">
          <h2 className="section-title6">Ethnic</h2>
          <br />
          <div className='flex'>
            <p className="section-desc">Embrace the cultural heritage of India with our exquisite ethnic wear collection, where tradition meets contemporary flair. From vibrant sarees to regal sherwanis, each piece is a testament to timeless elegance and refined taste, meticulously designed to celebrate the richness of Indian craftsmanship and heritage.</p>
            <div className="image-wrapper">
              <img className="suiting" src={ethnic2} alt="img" />
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className='container'>
        <div>
          <p className='end'>At Sujit Enterprises, we believe that clothing is more than just a garment; it's an expression of individuality and style. With our commitment to quality, craftsmanship, and customer satisfaction, we invite you to explore our curated collections and experience the epitome of sartorial excellence.</p>
        </div>
        <br/>
        <Link to="/collection" className="endbutton">
          Our Collection
        </Link>
      </section>
      <br />
    </>
  )
}
