import axios from "axios";

export const getServices = () => {
    return async (dispatch) => {
        dispatch({ type: 'GET_SERVICES' });
        try {
            const response = await axios.get("https://sujit-backend.onrender.com/api/services");
            if (response && response.data.data) {
                const sortedData = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                dispatch({ type: 'GET_SERVICES_COMPLETED', payload: sortedData });
            } else {
                dispatch({ type: 'GET_SERVICES_FAILED' });
            }
        } catch (error) {
            dispatch({ type: 'GET_SERVICES_FAILED' });
        }
    };
};



const serviceReducer = (state = { isLoading: false, servicesData: [] }, action) => {

    if (action.type === 'GET_SERVICES') {
        return {
            ...state,
            isLoading: true,
        }
    }

    if (action.type === 'GET_SERVICES_COMPLETED') {
        return {
            isLoading: false,
            servicesData: action.payload
        }
    }
    if (action.type === 'GET_SERVICES_FAILED') {
        return {
            isLoading: false,
            servicesData: []
        }
    }

    return state
}


export default serviceReducer