import React from 'react';
import Slider from '../../slider/Slider';
import img from "../../../assets/Since.png";
import ethnic from "../../../assets/ethnic.jpg";
import { Link } from 'react-router-dom';
import "./Home.css";
import "./style.css";

export default function Home() {
  

  return (
    <>
      <div className='slider'>
        <Slider />
      </div>
      <section className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content">
                <h2 className="section-title">About Us</h2>
                <br />
                <h3>Sujit Enterprises</h3>
                <br />
                <div className='flex'>
                  <p className="section-desc">Sujit Enterprises : Supplier, wholesaler of cotton shirting fabric, linen fabrics, linen shirting fabric, polyester fabric, printed shirting fabric, satin fabric, shirting, suiting, viscose fabric since 2014 in Mumbai, Maharashtra, India.
                    <br />
                    <br />
                    <Link to="/about"><button className='button'>KNOW MORE</button></Link></p>
                  <div className="image-wrapper">
                  <img src={img} alt="img" className="about-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='ourbusiness'>
        <div className='container'>
          <h2>Our Bussiness</h2>
          <br />
          <br />
          <div className='image-block'>
            <Link to="/ourbusiness">
            <div className='imag1'>
              <img className="img" src="https://siyaram-images.s3.ap-south-1.amazonaws.com/images/brands/siyaram-suitings-and-shirtings/box2.jpg" alt="img" />
              <p className="caption">Shirting
                <br />
                <Link to="/ourbusiness" className='discover'>Discover More</Link>
                <hr />
              </p>
            </div>
            </Link>
            <Link to="/ourbusiness">
            <div className='imag1'>
              <img className="img" src="https://images.unsplash.com/photo-1600091166971-7f9faad6c1e2?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="img" />
              <p className="caption">Suiting
                <br />
                <Link to="/ourbusiness" className='discover'>Discover More</Link>
                <hr />
              </p>
            </div>
            </Link>
            <Link to="/ourbusiness">
            <div className='imag1'>
              <img className="img" src={ethnic} alt="img" />
              <p className="caption">Ethnic
                <br />
                <Link to="/ourbusiness" className='discover'>Discover More</Link>
                <hr />
              </p>
            </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
