import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram,FaWhatsapp} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import "./Footer.css";
import Copy from './Copy';

export default function Footer() {
  return (
    <>
    <footer className="footer">
      <div className="container">
        <div className="footer-columns">
          <div className="footer-column">
            <h3 className="footer-heading">Our Company</h3>
            <ul className="footer-links">
              <li><Link to="/about" className="footer-link">About Us</Link></li>
              <li><Link to="/store" className="footer-link">Our Reach</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3 className="footer-heading">Newsroom</h3>
            <ul className="footer-links">
              <li><Link to="/sujit" className="footer-link">Media contact</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3 className="footer-heading">Quick Links</h3>
            <ul className="footer-links">
              <li><Link to="/ourbusiness" className="footer-link">Our Businesses</Link></li>
              <li><Link to="/contact" className="footer-link">Contact</Link></li>

            </ul>
          </div>
          <div className="footer-column">
            <h3 className="footer-heading">Get In Touch</h3>
            <div className="footer-contact">
              <FaLocationDot className="footer-icon" />
              <p className="footer-text">SUJIT ENTERPRISES, SHOP No - 596, KRISHNARAJ GALLY, M.J MARKET,MUMBAI – 400002 <br />
              phone : <Link to="tel:+91 9769247050">+91 97692 47050</Link>
              <br/>
              <Link to="mailto:sujitenterprises.2014@gmail.com" className="footer-link">sujitenterprises.2014@gmail.com</Link>
              </p>
            </div>
            <br/>
            <div className="footer-contact">
              <div className='addph'>
            <p className='add'>Monday To Saturday - 9:30AM To 7:30PM</p>
              </div>
            </div>
          </div>
          <div className="footer-column">
            <h3 className="footer-heading">Follow Us :</h3>
            <div className='footer-social'>
              <Link to="http://www.instagram.com/sujitenterprises?igsh=ZzFzaXVMmtwb2tx" target="_blank" rel="noreferrer" ><FaInstagram className="footer-social-icon" /></Link>
              <Link to="https://wa.me/+919769247050" target="_blank" rel="noreferrer"><FaWhatsapp className="footer-social-icon" /></Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <Copy/>
    </>
  );
}
